import { Box } from "@mui/material";
import { TextField, Stack } from "@mui/material";
import { useEffect, useState } from "react";
import ChatUserList from "./ChatUserList";
const SERVER = process.env.REACT_APP_WEBSOCKET_SERVER_URL || "http://localhost:6969";

const ChatHome = () => {
    const [search, setSearch] = useState('');
    const [state, setState] = useState({
        channels: [],
        filteredChannels: [],
        socket: null,
        channel: null
    });

    useEffect(() => {
        const loadChannels = async () => {
            fetch(`${SERVER}/getChannels`).then(async response => {
                let data = await response.json();
                setState({ channels: data.channels, filteredChannels: data.channels });
            })
        }

        loadChannels();
    }, []);

    const handleChange = (e) => {
        setSearch(e.currentTarget.value);
        const filteredChannels = state.channels.map((channel) => {
            if (search === '' || channel?.name?.toLowerCase().includes(search.toLowerCase())) {
                return channel;
            }
        });
        setState({ channels: state.channels, filteredChannels: filteredChannels });
    }

    return (
        <Box
            sx={{
                backgroundColor: 'primary.dark',
            }}>
            <Stack direction="column" spacing={2}>
                <form onSubmit={(e) => e.preventDefault()}>
                    <TextField id="outlined-basic"
                        label="Search"
                        variant="outlined"
                        autoComplete="off"
                        autoFocus
                        value={search}
                        onChange={handleChange} />
                </form>
                <ChatUserList users={state.filteredChannels} />
            </Stack>
        </Box >
    );
}

export default ChatHome;

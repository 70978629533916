import React from "react";
import { Avatar, Box, List, ListItem, ListItemAvatar, ListItemText, Divider, Typography, Grid, Stack, TextField, IconButton } from "@mui/material";
import { useAuth0 } from "@auth0/auth0-react";
import Loading from "../Loading";
import MatchPreview from "./MatchPreview";

function generate(array, element) {
    return array.map((value) =>
        React.cloneElement(element, {
            key: value,
            children: getCardPreview(value)
        }),
    );
}

function getCardPreview(value) {
    return (<MatchPreview match={value} />)
}

const MatchesHome = () => {
    const { isLoading, error } = useAuth0();
    const matches = [0, 1, 2, 3, 4, 5]

    // useEffect(() => {
    //     const getMatches = async () => {
    //         try {
    //             const userDetailsByIdUrl = `https://my.api.mockaroo.com/matches.json`;
    //             await fetch(userDetailsByIdUrl, {
    //                 headers: {
    //                     "X-API-Key": "250000a0",
    //                 },
    //             }).then(response => response.json()
    //                 .then(body => setMatches(body))
    //                 .catch(err => console.error(err)))
    //                 .catch(err => console.error(err));
    //         } catch (e) {
    //             console.error(e);
    //         }
    //     };

    //     getMatches();
    // }, []);

    if (isLoading) {
        return <Loading />;
    }

    if (error) {
        return <div>Oops... {error.message}</div>;
    }

    return (
        <Box
            sx={{
                backgroundColor: 'primary.dark',
            }}>
            <Stack direction="column" spacing={2} justifyContent="center" alignContent="center">
                <Typography align="center">You have ({matches.length}) new matches!</Typography>
            </Stack>
            <Grid container spacing={3}>
                {generate(matches,
                    <Grid item xs={12} sm={4} md={3}>

                    </Grid>
                )}
            </Grid>
        </Box >
    )
}

export default MatchesHome;

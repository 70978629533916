/* ------ IMPORTING FILES ------- */
import '../../css/room.css'
import '../../css/roomGroup.css'
import React, { useRef, useEffect, useState } from "react";
import { useParams, useNavigate } from 'react-router-dom';
import { Stack, TextField, IconButton, Snackbar, Alert, Slide } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import MicIcon from '@mui/icons-material/Mic';
import MicOffIcon from '@mui/icons-material/MicOff';
import VideocamIcon from '@mui/icons-material/Videocam';
import VideocamOffIcon from '@mui/icons-material/VideocamOff';
import PhoneDisabledIcon from '@mui/icons-material/PhoneDisabled';
import useVideoChat from '../../hooks/useVideoChat';

// Streaming Video of the user
const Video = (props) => {
    const ref = useRef();

    useEffect(() => {
        props.peer.on("stream", stream => {
            ref.current.srcObject = stream;
        })
    }, [props.peer]);

    return (
        <video class="groupVideo" playsInline autoPlay ref={ref} />
    )
}

const RoyaleRoom = (props) => {

    // variables for different functionalities of video call
    let { id } = useParams();
    const { userVideo, peers, audio, video, toggleAudio, toggleVideo, hangUp } = useVideoChat(id);
    let navigate = useNavigate();
    const [copyToast, setCopyToast] = useState(false);
    const handleToastClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setCopyToast(false);
    }

    return (
        <div class="row group-call">
            <div col="col-12">
                <div class="videos">
                    <video class="groupVideo" muted ref={userVideo} autoPlay playsInline />
                    {peers.map((peer) => {
                        return (
                            <Video class="groupVideo" key={peer.peerID} peer={peer.peer} />
                        );
                    })}
                </div>

                <div id="button-box">
                    <IconButton onClick={toggleAudio}>
                        {audio ? <MicIcon /> : <MicOffIcon />}
                    </IconButton>
                    <IconButton onClick={() => { hangUp(); navigate("/royale"); }}>
                        <PhoneDisabledIcon />
                    </IconButton>
                    <IconButton onClick={toggleVideo}>
                        {video ? <VideocamIcon /> : <VideocamOffIcon />}
                    </IconButton>
                </div>

                <div>
                    <Stack direction="row" spacing={2} justifyContent="center">
                        <TextField id="outlined-basic" label="Invite Link" variant="outlined" value={window.location} />
                        <IconButton aria-label="delete" onClick={() => { navigator.clipboard.writeText(window.location); setCopyToast(true); }}>
                            <ContentCopyIcon />
                        </IconButton>
                    </Stack>
                    <Stack direction="row" spacing={2} justifyContent="center">
                        <TextField id="outlined-basic" label="Invite Code" variant="outlined" value={id} />
                        <IconButton aria-label="delete" onClick={() => { navigator.clipboard.writeText(id); setCopyToast(true); }}>
                            <ContentCopyIcon />
                        </IconButton>
                    </Stack>
                    <Stack spacing={2} sx={{ width: '100%' }}>
                        <Snackbar open={copyToast}
                            autoHideDuration={1000}
                            onClose={handleToastClose}
                            TransitionComponent={(props) => <Slide {...props} direction='up' />}
                            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
                            <Alert onClose={handleToastClose} severity="success" sx={{ width: '100%' }}>
                                Invite Copied!
                            </Alert>
                        </Snackbar>
                    </Stack>
                </div>
            </div>
        </div >
    );
};

export default RoyaleRoom;

import { useEffect, useRef, useState } from "react";
import socketIOClient from "socket.io-client";
import Peer from "simple-peer";

const SOCKET_SERVER_URL = process.env.REACT_APP_WEBSOCKET_SERVER_URL || "http://localhost:6969";

// setting the constraints of video box
const videoConstraints = {
    height: window.innerHeight / 2,
    width: window.innerWidth / 2
};

const useVideoChat = (roomId) => {
    // variables for different functionalities of video call
    const [peers, setPeers] = useState([]);
    const [audio, setAudio] = useState(true);
    const [video, setVideo] = useState(true);
    const socketRef = useRef();
    const userVideo = useRef();
    const peersRef = useRef([]);
    const userStream = useRef();

    useEffect(() => {
        // Creates a WebSocket connection
        socketRef.current = socketIOClient(SOCKET_SERVER_URL + "/video", {
            query: { roomId },
        });

        // asking for audio and video access
        navigator.mediaDevices.getUserMedia({ audio: true, video: videoConstraints }).then(stream => {

            // streaming the audio and video
            userVideo.current.srcObject = stream;
            userStream.current = stream;

            socketRef.current.emit("join room group", roomId);

            // getting all user for the new user joining in
            socketRef.current.on("all users", users => {
                const peers = [];
                console.log('got users from server: ', users);
                // adding the new user to the group
                users.forEach(userID => {
                    const peer = createPeer(userID, socketRef.current.id, stream);
                    peersRef.current.push({
                        peerID: userID,
                        peer,
                    })
                    peers.push({
                        peerID: userID,
                        peer,
                    });
                })
                setPeers(peers);
            })

            // sending signal to existing users after new user joined
            socketRef.current.on("user joined", payload => {
                const peer = addPeer(payload.signal, payload.callerID, stream);
                console.log('user joined: ', peer);
                peersRef.current.push({
                    peerID: payload.callerID,
                    peer,
                })

                const peerObj = {
                    peer,
                    peerID: payload.callerID
                }

                setPeers(users => [...users, peerObj]);
            });

            // exisisting users recieving the signal
            socketRef.current.on("receiving returned signal", payload => {
                const item = peersRef.current.find(p => p.peerID === payload.id);
                item.peer.signal(payload.signal);
            });

            // handling user disconnecting
            socketRef.current.on("user left", id => {
                // finding the id of the peer who just left
                const peerObj = peersRef.current.find(p => p.peerID === id);
                if (peerObj) {
                    peerObj.peer.destroy();
                }

                // removing the peer from the arrays and storing remaining peers in new array
                const peers = peersRef.current.filter(p => p.peerID !== id);
                peersRef.current = peers;
                setPeers(peers);
            })
        })

        // Destroys the socket reference
        // when the connection is closed
        return () => {
            socketRef.current.disconnect();
        };
    }, [roomId]);

    // creating a peer object for newly joined user
    function createPeer(userToSignal, callerID, stream) {
        const peer = new Peer({
            initiator: true,
            trickle: false,
            stream,
        });

        peer.on("signal", signal => {
            socketRef.current.emit("sending signal", { userToSignal, callerID, signal })
        })

        return peer;
    }

    // adding the newly joined peer to the room
    function addPeer(incomingSignal, callerID, stream) {
        const peer = new Peer({
            initiator: false,
            trickle: false,
            stream,
        })

        peer.on("signal", signal => {
            socketRef.current.emit("returning signal", { signal, callerID })
        })

        peer.signal(incomingSignal);
        return peer;
    }

    // Toggle Video
    function toggleVideo() {
        setVideo(!video);
        console.log('video toggle ', video);
        userStream.current.getVideoTracks()[0].enabled = video;
    }

    // Toggle Audio
    function toggleAudio() {
        setAudio(!audio);
        console.log('audio toggle ', audio);
        userStream.current.getAudioTracks()[0].enabled = audio;
    }

    // Hanging up the call
    function hangUp() {
        userStream.current.getAudioTracks()[0].enabled = false;
        userStream.current.getAudioTracks()[0].stop();
        userStream.current.getVideoTracks()[0].enabled = false;
        userStream.current.getVideoTracks()[0].stop();
        socketRef.current.disconnect();
    }

    return { peers, userVideo, audio, video, toggleAudio, toggleVideo, hangUp };
};

export default useVideoChat;

import { Box, Stack } from "@mui/material"
import ChatHeader from "./ChatHeader"
import ChatInput from "./ChatInput"
import { useParams } from "react-router-dom"
import ChatMessageList from "./ChatMessageList"
import useChat from "../../hooks/useChat";

const io = require('socket.io-client');
const SERVER = process.env.REACT_APP_WEBSOCKET_SERVER_URL || "http://localhost:6969";
const socket = io(SERVER);

export default function ChatContainer() {
    let { id } = useParams();
    const { messages, sendMessage } = useChat(id);
    const user = {
        id: id,
        name: id
    }

    socket.on('connection', () => {
        console.log('successfully connected to websocket server: ', SERVER);
    });

    return (
        <Box
            sx={{ backgroundColor: 'primary.dark' }}>
            <Stack direction="column" spacing={2} justifyContent="center">
                <ChatHeader user={user} />
                <ChatMessageList messages={messages} />
                <ChatInput sendMessage={sendMessage} />
            </Stack>
        </Box>
    )
}

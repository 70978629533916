import { useState, useEffect, Fragment } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import Loading from "./Loading";

const ProfileHome = () => {

    const { user, isLoading, error, getAccessTokenSilently } = useAuth0();
    const [userMetadata, setUserMetadata] = useState(null);

    useEffect(() => {
        const getUserMetadata = async () => {
            const domain = process.env.REACT_APP_AUTH0_DOMAIN;

            try {
                const accessToken = await getAccessTokenSilently({
                    audience: `https://${domain}/api/v2/`,
                    scope: "read:current_user"
                });

                const metadataResponse = await fetch(`https://${domain}/api/v2/users/${user.sub}`, {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                });

                const { user_metadata } = await metadataResponse.json();
                setUserMetadata(user_metadata);
            } catch (e) {
                console.log(e.message);
            }
        };

        // getUserMetadata();
    }, [getAccessTokenSilently, user?.sub]);

    if (isLoading) {
        return <Loading />;
    }

    if (error) {
        return <div>Oops... {error.message}</div>;
    }

    return (
        <Fragment>
            <div>Profile<br></br>
                <img src={user.picture} alt={user.name} />
                <h2>User Data</h2>
                <pre>{JSON.stringify(user, null, 2)}</pre>
                <h3>User Metadata</h3>
                {userMetadata ? (
                    <pre>{JSON.stringify(userMetadata, null, 2)}</pre>
                ) : (
                    "No user metadata defined"
                )}
            </div>
        </Fragment>
    );
}

export default ProfileHome;

import { Avatar, List, ListItem, ListItemAvatar, ListItemText } from "@mui/material";
import React, { useEffect, useState } from "react";

export default function ChatMessageList(props) {
    const [messageListItems, setMessageListItems] = useState([]);

    useEffect(() => {
        const listItems = props.messages.map((message) => {
            var item = [
                <ListItem key={message?.id}>
                    <ListItemAvatar>
                        <Avatar>

                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary={message?.body}></ListItemText>
                </ListItem>
            ]
            return [...listItems, item];
        });
        setMessageListItems(listItems);
    }, [props.messages])

    return (
        <List dense sx={{
            overflow: 'auto',
            maxHeight: 300,
            maxWidth: 360,
            width: '100%'
        }}>
            {messageListItems}
        </List>
    )
}

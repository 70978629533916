import { Avatar, AppBar, Container, Stack, Typography } from "@mui/material"

export default function ChatHeader(props) {

    return (
        <AppBar position="static">
            <Container maxWidth="xl">
                <Stack direction="row" spacing={2}>
                    <Avatar alt={props.user?.name} src={props.user?.picture} />
                    <Typography textAlign="center">{props.user?.name}</Typography>
                </Stack>
            </Container>
        </AppBar>
    )
}

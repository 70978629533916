import { TextField, Button, Container } from "@mui/material";
import SendIcon from '@mui/icons-material/Send';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { useNavigate } from "react-router-dom";

const RoyaleHome = () => {
    let navigate = useNavigate();

    function startRoyale() {
        const id = Math.floor(Math.random() * 999);
        navigate(`/royale/${id}`);
    }

    function joinRoyale(e) {
        e.preventDefault();
        if (e.target[0].value) {
            navigate(`/royale/${e.target[0].value}`);
        }
    }

    return (
        <Box
            sx={{
                backgroundColor: 'primary.dark',
            }}>
            <Container>
                <Stack direction="column" spacing={2}>
                    <Button variant="contained" endIcon={<EmojiEventsIcon />} onClick={() => startRoyale()}>
                        Start Royale!
                    </Button>
                </Stack>
            </Container>
            <Divider variant="middle" />
            <Container>
                <form onSubmit={joinRoyale}>
                    <TextField id="outlined-basic"
                        label="Join Code"
                        variant="outlined"
                        type={"password"}
                        autoComplete={"off"}
                        autoFocus
                        required />
                    <Button variant="contained" endIcon={<SendIcon />} type="submit">
                        Join
                    </Button>
                </form>
            </Container>
        </Box>
    )
}

export default RoyaleHome;

import { useParams } from "react-router-dom";
import { useEffect, useState, Fragment } from "react";
import { useNavigate } from "react-router-dom";

const MatchDetail = () => {
    let navigate = useNavigate();
    let { id } = useParams();
    const [match, setMatch] = useState({});
    const [matchedUser, setMatchedUser] = useState({});

    useEffect(() => {
        const getMatches = async () => {
            try {
                const userDetailsByIdUrl = `https://my.api.mockaroo.com/matches/${id}.json`;
                await fetch(userDetailsByIdUrl, {
                    headers: {
                        "X-API-Key": "250000a0",
                    },
                }).then(response => response.json()
                    .then(body => { setMatch(body) })
                    .catch(err => console.error(err)))
                    .catch(err => console.error(err));
            } catch (e) {
                console.error(e);
            }
        };

        getMatches();
    }, [id, setMatch]);

    useEffect(() => {
        const getMatches = async () => {
            try {
                const userDetailsByIdUrl = `https://my.api.mockaroo.com/users/${match?.matchedUserId}.json`;
                await fetch(userDetailsByIdUrl, {
                    headers: {
                        "X-API-Key": "250000a0",
                    },
                }).then(response => response.json()
                    .then(body => { setMatchedUser(body) })
                    .catch(err => console.error(err)))
                    .catch(err => console.error(err));
            } catch (e) {
                console.error(e);
            }
        };

        getMatches();
    }, [match?.matchedUserId, setMatchedUser]);


    return (
        <Fragment>
            <div>Match<br></br>
                <h2>{matchedUser?.first_name}, {matchedUser?.gender}, {matchedUser?.age}</h2>
                <img src={matchedUser?.avatar} alt={matchedUser?.email} />
                <h2>Match Data</h2>
                <pre>{JSON.stringify(matchedUser, null, 2)}</pre>
                <button onClick={() => { accept(matchedUser); navigate("/matches"); }}>Accept</button>
                <button onClick={() => { deny(matchedUser); navigate("/matches"); }}>Deny</button>
            </div>
        </Fragment>
    );

}

const accept = (matchedUser) => {
    // add user to chats/contacts
    console.log("Accepted " + matchedUser.first_name);
}

const deny = (matchedUser) => {
    // remove use from match list
    // prevent from appearing again?
    console.log("Denied " + matchedUser.first_name);
}

export default MatchDetail;

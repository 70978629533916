import { List, ListItemButton, ListItemAvatar, ListItemText, Avatar } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";

export default function ChatUserList(props) {
    let navigate = useNavigate();

    return (
        <List sx={{
            overflow: 'auto',
            maxHeight: 300,
            maxWidth: 360,
            width: '100%'
        }}>
            {props.users.map((user) => {
                return (user &&
                    <ListItemButton key={user?.id} onClick={() => navigate(`/chat/${user?.id}/messages`)}>
                        <ListItemAvatar>
                            <Avatar />
                        </ListItemAvatar>
                        <ListItemText primary={user?.name}></ListItemText>
                    </ListItemButton >
                )
            })}
        </List>
    )
}

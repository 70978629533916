import React from "react";
import { Card, CardHeader, CardActions, CardMedia, CardContent, Typography, IconButton } from "@mui/material";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';

export default function MatchPreview(props) {

    function acceptMatch() {
        // add matched user to current user contacts
        // navigate to new message with the matched user
    }

    function denyMatch() {
        // remove matched user from current user match list
        // prevent matched user from appearing in current user match list again
    }

    return (
        <Card>
            <CardHeader
                action={
                    <IconButton aria-label="settings">
                        <MoreVertIcon />
                        {/* add options to view full profile */}
                    </IconButton>
                }
                title={props.match}
                subheader="Gender, Sex, Age"
            />
            {/* <CardMedia
                image={props.url}
                title="Lorem Ipsum"
            /> */}
            <CardContent>
                <Typography variant="body2" color="textSecondary" component="p">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                    eiusmod tempor incididunt ut labore et dolore magna aliqua. Tincidunt
                    lobortis feugiat vivamus at augue.
                </Typography>
            </CardContent>
            <CardActions disableSpacing>
                <IconButton aria-label="accept" onClick={acceptMatch}>
                    <CheckIcon />
                </IconButton>
                <IconButton aria-label="deny" onClick={denyMatch}>
                    <CloseIcon />
                </IconButton>
            </CardActions>
        </Card>
    );
}

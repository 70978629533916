import { Container, TextField, IconButton } from "@mui/material";
import SendIcon from '@mui/icons-material/Send';
import { useState } from "react";

export default function ChatInput(props) {
    const [value, setValue] = useState('');

    const submitInput = (event) => {
        event.preventDefault();
        props.sendMessage(value);
        setValue('');
    }

    return (
        <Container>
            <form data-lpignore="true" onSubmit={submitInput}>
                {/* bug using multiline prop because of lastpass extension appearently */}
                {/* https://github.com/mui/material-ui/issues/14860 */}
                {/* https://community.logmein.com/t5/LastPass-Support-Discussions/onloadwff-js-Assertion-failed-Input-argument-is-not-an-HTML/m-p/246491 */}
                <TextField id="outlined-multiline-basic"
                    required
                    autoFocus
                    maxRows={4}
                    placeholder="Type your message here..."
                    variant="outlined"
                    type={"text"}
                    autoComplete="off"
                    value={value}
                    onChange={(e) => setValue(e.currentTarget.value)} />
                <IconButton type="submit">
                    <SendIcon />
                </IconButton>
            </form>
        </Container>
    )
}

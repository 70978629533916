import React from "react";
import { Route, Routes } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import Footer from "./components/Footer";
import ProfileHome from "./components/ProfileHome";
import MatchesHome from "./components/match/MatchesHome";
import MatchesDetail from "./components/match/MatchDetail";
import RoyaleHome from "./components/royale/RoyaleHome";
import ResponsiveAppBar from "./components/ResponsiveAppBar";
import ChatHome from "./components/chat/ChatHome";
import { Box } from "@mui/material";
import { CssBaseline } from "@mui/material";
import RoyaleRoom from "./components/royale/RoyaleRoom";
import ChatContainer from "./components/chat/ChatContainer";

const App = () => {

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <CssBaseline />
      <ResponsiveAppBar />
      <Routes>
        <Route path="/" element={
          <RequireAuth>
            <ProfileHome />
          </RequireAuth>} />
        <Route path="/login" element={
          <p>Log in to continue.</p>
        } />
        <Route path="/profile" element={
          <RequireAuth>
            <ProfileHome />
          </RequireAuth>
        } />
        <Route path="/chat" element={
          <RequireAuth>
            <ChatHome />
          </RequireAuth>
        } />
        <Route path="/chat/:id/messages" element={
          <RequireAuth>
            <ChatContainer />
          </RequireAuth>
        } />
        <Route path="/matches" element={
          <RequireAuth>
            <MatchesHome />
          </RequireAuth>
        } />
        <Route path="/matches/:id" element={
          <RequireAuth>
            <MatchesDetail />
          </RequireAuth>
        } />
        <Route path="/royale" element={
          <RequireAuth>
            <RoyaleHome />
          </RequireAuth>
        } />
        <Route path="/royale/:id" element={
          <RequireAuth>
            <RoyaleRoom />
          </RequireAuth>
        } />
      </Routes>
      <Footer />
    </Box>
  );
}

function RequireAuth({ children }) {
  let { isAuthenticated } = useAuth0();
  return isAuthenticated ? children : <p>Log in to continue.</p>;
}

export default App;
